import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UrsugarmedsComponent } from './ursugarmeds.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: UrsugarmedsComponent,
  },
  {
    path: 'prescriptions',
    loadChildren: './prescriptions/prescriptions.module#PrescriptionsModule',
  },
  {
    path: 'leads',
    loadChildren: './leads/leads.module#LeadsModule',
  },
  {
    path: 'orders',
    loadChildren: './orders/orders.module#OrdersModule',
  },
  {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfileModule',
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: 'reports',
    loadChildren: './reports/reports.module#ReportsModule',
  },
  {
    path: 'medicine',
    loadChildren: './medicinelist/medicinelist.module#MedicinelistModule',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UrsugarRoutingModule {

}
