import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrsugarRoutingModule } from './ursugarmeds-routing.module';
import { UrsugarmedsComponent } from './ursugarmeds.component';


export const UrsugarmedsMenu = [
  {
    title: ' Prescriptions',
    icon: 'fa fa-clone',
    link: '/ursugarmeds/prescriptions/view',
  },
  {
    title: ' New Leads',
    icon: 'fa fa-warehouse',
    link: '/ursugarmeds/leads/view',
  },
  {
    title: ' Orders',
    icon: 'fa fa-shopping-cart',
    link: '/ursugarmeds/orders/view',
  },
  {
    title: '  profile',
    icon: 'fa fa-user',
    link: '/ursugarmeds/profile/view',
  },
  {
    title: 'Reports',
    icon: 'fa fa-chart-pie',
    link: '/ursugarmeds/reports/view',
  },
  ];

@NgModule({
  declarations: [UrsugarmedsComponent],
  imports: [
    CommonModule,
    UrsugarRoutingModule,
  ],
})
export class UrsugarmedsModule { }
